const { addPageAction } = require('../util/new_relic.js');
import ImsAuth from '../util/ims_auth.js';

const ANALYTICS_CONTEXT = {
  disableEvents: true,
  consumer: {
    name: 'Adobe Fonts',
    version: '1.0',
    platform: 'Web',
    device: 'na',
    os_version: 'na'
  }
};

const THEME_COLOR = 'light';

/**
 * This component is responsible for loading and initializing the Adobe UniversalNav
 * header content.
 *
 * https://wiki.corp.adobe.com/display/CCHome/Universal+Nav+Integration+Guidelines
 *
 * Profile Switch integrated via the Account Menu Mini-App and Mini-App Profile Switcher
 * Jan 8, 2024: Currently implemented profile switcher with UniversalNav v1.4
 * Update / check the version we are using in typekit.yml
 *
 * https://wiki.corp.adobe.com/display/IdentityACCM/Account+Menu+Mini-App#AccountMenuMiniApp-CustomeventscommunicationandonMessagedeprecationcustom_event_migration
 * https://wiki.corp.adobe.com/display/IdentityACCM/Profile+Switching+Integration+Guide#ProfileSwitchingIntegrationGuide-Analytics[WIP]ProfileSwitchMiniApp-ProfileSwitch-Analytics
 *
 * NOTE: "localhost" is not whitelisted for any of the IMS related functionality.
 * If you want to test this functionality locally, you will need to use
 * https://localhost.adobe.com:3000/ instead of http://localhost:3000/.
 */
class UniversalNav extends HTMLElement {
  async connectedCallback() {
    const target = this.querySelector('#universal-nav-target');
    if (!target) {
      return;
    }

    try {
      await Promise.all([this._loadCSS(), this._loadScript()]);
      this._initUniversalNav(target);
    } catch (error) {
      if (this.getAttribute('env') !== 'prod') {
        console.error('There was an error loading UniversalNav:', error);
      }
    }
  }

  _getChildren() {
    const children = [];

    // Notifications will be gated behind a flipper flag until we have
    // approval to enable it in production.
    if (this.hasAttribute('enable-notifications')) {
      children.push({
        name: 'notifications',
        attributes: {
          notificationsConfig: {
            applicationContext: {
              appID: this.getAttribute('ansAppId')
            }
          }
        }
      });
    }

    children.push(
      {
        name: 'app-switcher',
        attributes: {}
      },
      {
        name: 'profile',
        attributes: {
          messageEventListener: async (event) => {
            const { name, payload, executeDefaultAction, trackCompleteAction } =
              event.detail;
            this._handleAccountMenuMessages(
              name,
              payload,
              executeDefaultAction,
              trackCompleteAction
            );
          },
          callbacks: {
            // UniversalNav doesn't handle the logic for signing in
            // so we need to provide the implementation here.
            onSignIn: () => {
              if (window.adobeIMS) {
                addPageAction('typekit.click.sign-in');
                /**
                 * window.adobeIMS.signIn() will redirect the user to the IMS sign-in workflow,
                 * but our app will not capture the new token when the user is redirected back,
                 * instead it loads the visitor version of the site and then captures the token
                 * after a second page reload
                 *
                 * Redirect user with the provided ims_login_url ensures both front and backend will receive
                 * the new token at the same time when they are redirected back to tk/tk
                 */
                window.location.href = this.getAttribute('sign-in-url');
              }
            }
          },

          componentLoaderConfig: this._initialProfileConfig()
        }
      }
    );

    return children;
  }

  _initUniversalNav(target) {
    // eslint-disable-next-line new-cap
    window.UniversalNav({
      target,
      env: this.getAttribute('env'),
      analyticsContext: ANALYTICS_CONTEXT,
      theme: THEME_COLOR,
      locale: this.getAttribute('locale'),
      children: this._getChildren(),
      imsClientId: this.getAttribute('ims-client-id'),
      isSectionDividerRequired: false
    });
  }

  _loadScript = () => {
    if (!this.hasAttribute('js')) {
      return;
    }

    const script = document.createElement('script');
    script.src = this.getAttribute('js');

    return new Promise((resolve, reject) => {
      script.addEventListener('load', resolve);
      script.addEventListener('error', reject);
      document.head.appendChild(script);
    });
  };

  _loadCSS = () => {
    if (!this.hasAttribute('css')) {
      return;
    }

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = this.getAttribute('css');

    return new Promise((resolve, reject) => {
      link.addEventListener('load', resolve);
      link.addEventListener('error', reject);
      document.head.appendChild(link);
    });
  };

  /**
   * Set the initial profile configuration for all users and visitors
   *
   * https://wiki.corp.adobe.com/display/IdentityACCM/Account+Menu+Mini-App#AccountMenuMiniApp-ComponentLoaderconfigparamsAccountMenuMiniApp-componentLoader-config-params
   * @returns {Object}
   */
  _initialProfileConfig = () => {
    return {
      cdnEnvironment: this.getAttribute('env'),
      config: {
        enableLocalSection: true,
        context: {
          environment: this.getAttribute('env'),
          locale: this.getAttribute('locale')
        },
        enableProfileSwitcher: this.hasAttribute('enable-profile-switcher'),
        imsClientId: this.getAttribute('ims-client-id'),
        miniAppContext: {
          colorScheme: THEME_COLOR
        }
      }
    };
  };

  /**
   * Update the profile config when there is a logged in user
   * @returns {nothing}
   */
  _updateProfileConfig = async () => {
    try {
      const accessToken = await window.adobeIMS.getAccessToken().token;
      const userProfile = await window.adobeIMS.getProfile();
      const userId = userProfile?.userId;

      if (!accessToken || !userProfile) {
        throw new Error('Missing credentials');
      }

      const profileConfig = this._initialProfileConfig();
      profileConfig.config.getAccessToken = () => Promise.resolve(accessToken);
      profileConfig.config.getProfile = () => userProfile;
      profileConfig.config.userId = userId;
      window.UniversalNav?.updateChildAttribute(
        'profile',
        'componentLoaderConfig',
        profileConfig
      );
    } catch (error) {
      console.error('Error updating profile config:', error);
    }
  };

  /**
   * Based on the event name and payload's subType, handle the account menu events
   *
   * https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=PAN&title=RFC%3A+Mini-App+Standard+Messaging+Specification#RFC:MiniAppStandardMessagingSpecification-AnalyticsMessages
   * @param {*} eventName
   * @param {*} payload
   * @param {*} executeDefaultAction
   */
  _handleAccountMenuMessages = async (
    eventName,
    payload,
    executeDefaultAction,
    trackCompleteAction
  ) => {
    const { subType } = payload;

    if (eventName === 'System' && payload.subType === 'AppInitiated') {
      this._updateProfileConfig();
    }

    if (eventName === 'System' && subType === 'ProfileSwitch') {
      const profile = await executeDefaultAction();
      if (profile !== undefined) {
        trackCompleteAction();
        ImsAuth.switchProfile();
      }
    }

    if (eventName === 'System' && subType === 'SignOut') {
      addPageAction('typekit.click.sign-out');
      executeDefaultAction();
      trackCompleteAction();
      ImsAuth.signOut();
    }
  };
}

customElements.define('af-universal-nav', UniversalNav);
