const Freezeframe = require('freezeframe');
/**
 * A service for interacting with Freezeframe
 * @ngInject
 */
function FreezeframeService($document, $window) {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  let freezeframe;

  return {
    /**
     * Initializes Freezeframe across the entire page
     */
    init: function () {
      if ($window.addEventListener) {
        $window.addEventListener('DOMContentLoaded', () => {
          freezeframe = new Freezeframe({ selector: '.adobe-fonts-family-image-card--gif', warnings: false });
        });
      }
    },

    /**
    * Re-runs a new Freezeframe with window.requestAnimationFrame for pagination interactions
    */
    update: update,

    /**
    * Re-runs a new Freezeframe with window.requestAnimationFrame for foundries index page
    */
    deleteAndUpdateFeaturedFoundries: () => {
      removeFeaturedFoundriesElements();
      update();
    }
  };

  function update() {
    $window.requestAnimationFrame(() => {
      freezeframe = new Freezeframe({ selector: '.adobe-fonts-family-image-card--gif', warnings: false });
    });
  }

  /**
  * Look for and remove freezeframe elements from featured foundries badges and adobe-fonts-image-card-list items
  */
  function removeFeaturedFoundriesElements() {
    const listItems = $document[0].body.querySelector('ul.slides').children;
    const foundryBadges = $document[0].body.querySelectorAll('a.foundry-badge--link');

    if (listItems && foundryBadges) {
      removeFreezeframeElements(listItems);
      removeFreezeframeElements(foundryBadges);
    }
  }

  /**
  * Remove pre-existing freezeframe elements, like <canvas> and <div> with ff-container class.
  * These containers add onto a <img> each time we change the carousel
  *
  * For example, on the foundries index page, carousel is set by clicking on the foundry badge
  * And extraneous Freezeframe <div> and <canvas> elements need to be removed with each carousel update
  *
  */
  function removeFreezeframeElements(listItems) {
    $document.find('canvas').remove();
    for (let listItem of listItems) {

      if (listItem.firstElementChild && listItem.firstElementChild.matches('div.adobe-fonts-family-image-card')) {
        const imageCard = listItem.firstElementChild;
        const imageContainer = imageCard.firstElementChild;

        if (imageContainer.firstElementChild) {
          const ffContainer = imageContainer.firstElementChild;
          if (ffContainer.matches('div.ff-container')) {
            const image = ffContainer.firstElementChild;
            imageContainer.prepend(image);
            ffContainer.remove();
          }
        }
      } else if (listItem.firstElementChild && listItem.firstElementChild.matches('div.ff-container')) {
        const ffContainer = listItem.firstElementChild;
        const image = ffContainer.firstElementChild;
        listItem.prepend(image);
        ffContainer.remove();
      }
    }
  }
}

module.exports = FreezeframeService;
