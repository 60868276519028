const StyleSelectorModel = require('../models/style_selector_model.js');
const ProjectSelectorModel = require('../models/project_selector_model.js');
const AddFontsToWebProjectModel = require('../models/add_fonts_to_web_project_model.js');

const POST_LOGIN_ACTION_TYPE = require('../../util/post_login_actions/post_login_action_type.js').default;

/**
 * A service used to manage the "add fonts to web project" modal workflow.
 * @ngInject
 */
function AddFontsToWebProjectService($http, AuthenticationService, DialogService, FirstMileService) {
  var _projectSelectorModelSingleton;
  var _styleSelectorModelSingleton;
  var _addFontsToWebProjectModelSingleton;

  return {
    init: init,
    addFamilyToWebProject: addFamilyToWebProject,
    addFamilyToWebProjectWithAuthentication: addFamilyToWebProjectWithAuthentication,
    getAddFontsToWebProjectModel: getAddFontsToWebProjectModel,
    getEmbedCodeModel: getEmbedCodeModel,
    getFamilyAndProjectsInfo: getFamilyAndProjectsInfo,
    getProjectSelectorModel: getProjectSelectorModel,
    getStyleSelectorModel: getStyleSelectorModel,
    getWebfontProjectStylesModel: getWebfontProjectStylesModel,
    groupByTypekitFontFamilies: groupByTypekitFontFamilies,
    showAddFontsToWebProjectDialog: showAddFontsToWebProjectDialog,
    updateProjectDetails: updateProjectDetails
  };

  /**
   * Add the family to a web project, using the AuthenticationService to do a
   * full post login redirect if necessary.
   *
   * @param {String} familySlug
   * @param {String} variationSlug (optional)
   */
  function addFamilyToWebProjectWithAuthentication(familySlug, variationSlug, editorSaveState) {
    AuthenticationService.handleAuthenticationWithPostLoginAction(
      POST_LOGIN_ACTION_TYPE.ADD_TO_WEB_PROJECT, {
        familySlug: familySlug,
        variationSlug: variationSlug
      }).then(function() {
        addFamilyToWebProject(familySlug, variationSlug, editorSaveState);
      });
  }

  /**
   * Add the family to a web project.
   *
   * If a specific variation ID is passed in, only that single variation will be
   * shown in the dialog.  Otherwise, all of the available variations will be
   * shown.
   *
   * @param {String} familySlug
   * @param {String} variationSlug (optional)
   * @param {VariableFontEditorSaveState} editorSaveState (optional)
   */
  function addFamilyToWebProject(familySlug, variationSlug, editorSaveState) {
    _getFamilyDataForAddToWebProjectDialog(familySlug, variationSlug).then(function(data) {
      if (data.errors) {
        return;
      }

      showAddFontsToWebProjectDialog(
        data.family.slug,
        data.family.fonts,
        data.textSamples,
        data.defaultExampleText,
        editorSaveState);
    });
  }

  /**
   * Returns the existing instance of StyleSelectorModel.
   *
   * @returns {Object}
   */
  function getStyleSelectorModel() {
    return _styleSelectorModelSingleton;
  }

  /**
   * Returns the existing instance of ProjectSelectorModel.
   *
   * @returns {Object}
   */
  function getProjectSelectorModel() {
    return _projectSelectorModelSingleton;
  }

  /**
   * Returns the existing instance of AddFontsToWebProjectModel.
   *
   * @returns {Object}
   */
  function getAddFontsToWebProjectModel() {
    return _addFontsToWebProjectModelSingleton;
  }

  /**
   * Returns the existing instance of EmbedCodeModel.
   *
   * @returns {Object}
   */
  function getEmbedCodeModel() {
    return _addFontsToWebProjectModelSingleton.getEmbedCodeModel();
  }

  /**
   * Returns the existing instance of WebfontProjectStylesModel.
   *
   * @returns {Object}
   */
  function getWebfontProjectStylesModel() {
    return _addFontsToWebProjectModelSingleton.getWebfontProjectStylesModel();
  }

  /**
   * Returns the user's existing Projects and whether the families with the given opaque ids are CJK or not.
   *
   * @param {String} familySlug
   * @returns {Promise}
   */
  function getFamilyAndProjectsInfo(familySlug) {
    return $http.get('/web_project/family_and_projects_info.json', {params: {family_slug: familySlug}})
      .then(function(response){
        return response.data;
      });
  }

  /**
   * Returns the given fonts grouped by typekit font families.
   *
   * @param {Array} fonts
   * @returns {Object} families
   */
  function groupByTypekitFontFamilies(fonts) {
    var families = {};
    var orderedFamilyIds = [];
    fonts.forEach(function (font) {
      if (!families[font.family.web_id]) {
        families[font.family.web_id] = {
          web_id: font.family.web_id,
          web_name: font.family.web_name,
          variations: []
        };
        orderedFamilyIds.push(font.family.web_id);
      }
      families[font.family.web_id].variations.push(font);
    });

    var orderedFamilies = orderedFamilyIds.map(function (familyId) {
      return families[familyId];
    });

    return {
      familyIds: orderedFamilyIds,
      orderedFamilies: orderedFamilies
    };
  }

  /**
   * Displays the add to fonts web project dialog.
   * @param {Object} familySlug
   * @param {editorSaveState} editorSaveState
   */
  function showAddFontsToWebProjectDialog(familySlug, fonts, textSamples, exampleText, editorSaveState) {
    // The first time a user tries to add a font to a web project, they will be
    // shown the Typekit web project onboarding dialog. Once they've
    // acknowledged the modal, (or if we've recorded that the user has already
    // seen the modal), the web project dialog will be displayed.
    FirstMileService.showTypekitOnboardDialogIfNecessary().then(function() {
      init(familySlug, fonts, textSamples, exampleText);

      // The web component based add to web project dialog components and
      // supporting code will be lazy loaded.
      import('../../controllers/add_to_web_project_dialog.js').then(({ openAddToWebProjectDialog }) => {
        openAddToWebProjectDialog({
            // For now, we will continue to use the same data models
            // as the old Angular version of the dialog, but this code
            // can be simplified quite a bit.
          families: getStyleSelectorModel().getSortedFamilies(),
          projectSelectorModelPromise: getProjectSelectorModel().getProjectSelectorModelPromise(),
          editorSaveState
        });
      });
    });
  }

  /**
   * Initializes the service.
   *
   * @param {String} familySlug
   * @param {Array} fonts
   * @param {Object} textSamples
   * @param {String} exampleText
   */
  function init(familySlug, fonts, textSamples, exampleText) {
    var groupedFamilesinfo = groupByTypekitFontFamilies(fonts);
    var deferredFamilyAndProjectsInfo = getFamilyAndProjectsInfo(familySlug);
    _styleSelectorModelSingleton = new StyleSelectorModel(groupedFamilesinfo.orderedFamilies, textSamples, exampleText);
    var deferredFamilyAndProjectsInfoForSelector = deferredFamilyAndProjectsInfo.then(function (familyAndProjectsInfo) {
      return {
        projects: familyAndProjectsInfo.projects,
        is_family_CJK: familyAndProjectsInfo.is_family_CJK
      };
    });
    _projectSelectorModelSingleton = new ProjectSelectorModel(deferredFamilyAndProjectsInfoForSelector);
    _addFontsToWebProjectModelSingleton = new AddFontsToWebProjectModel($http, DialogService,
      deferredFamilyAndProjectsInfo, _projectSelectorModelSingleton, _styleSelectorModelSingleton);
  }

  /**
   * Get the extra data necessary to add the family to the a web project.
   *
   * If a specific variation slug is passed in, only the data for that specific
   * variation will be returned.
   *
   * @private
   * @param {String} familySlug
   * @param {String} variationSlug (optional)
   * @return {Promise}
   */
  function _getFamilyDataForAddToWebProjectDialog(familySlug, variationSlug) {
    var url = '/web_project/add_to_web_project_data/' + familySlug;
    if (variationSlug) {
      url += '?variation=' + variationSlug;
    }

    return $http.get(url).then(function(response) {
      return response.data;
    });
  }

  /**
   * Updates the selected families of the project, when user clicks on any project
   *
   * @param {Object} project
   */
  function updateProjectDetails(project) {
    $http.get('/web_project/families_in_project.json', {params: {project_id: project.id}})
      .then(function(response){
        var existingFamiliesInProject = response.data.typekit_font_families;
        getAddFontsToWebProjectModel().updateExistingFamiliesInProject(existingFamiliesInProject);
      });
  }
}

module.exports = AddFontsToWebProjectService;
