// Disable the window service warning to help migrate this
// file away from Angular.

require('@spectrum-web-components/icons-workflow/icons/sp-icon-search.js');

const CCDEvent = require('@adobe-fonts/site/events/CCDEvent.js');
const FlyoutEvent = require('@adobe-fonts/site/events/FlyoutEvent.js');
const { EVENT_NAME: SEARCH_PARAM_CHANGED_EVENT_NAME } = require('@adobe-fonts/site/events/SearchParamChangedEvent.js');
const { getData } = require('../../util/script_data.js');
const { releaseFocus, trapFocus } = require('../../util/focus.js');

const { addPageAction, setCustomAttribute, getReferrerHeader } = require('../../util/new_relic.js');

const {
  getActivityState,
  getAdobeProfileId,
  getDaysSinceSyncActivity,
  getPlan,
  setActivityState,
  setAdobeProfileId,
  setDaysSinceSyncActivity,
  setPlan
} = require('../../util/user_state.js');
const UrlService = require('../../util/url_service.js').default;

/**
 * @ngInject
 */
function MarketplaceHeaderController(
  $element,
  FamilyAutocompleteSearchService,
  FirstMileService,
  UserPrefsService
) {
  const self = this;

  self.$element = $element;
  self.FirstMileService = FirstMileService;
  self.FamilyAutocompleteSearchService = FamilyAutocompleteSearchService;
  self.UserPrefsService = UserPrefsService;

  self.initEventHandlers($element[0]);
  self._initSubHeader($element[0]);
  self._preloadData();
  self._setNewrelicCustomAttributes();

  // TODO(ephraim): remove this once UniversalNav is launched since we won't be
  // handling sign in this way anymore.
  const signIn = $element[0].querySelector('.adobe-fonts-header__sign-in');
  if (!signIn) {
    return;
  }

  signIn.addEventListener('click', () => {
    self._handleSignInButton();
  });
}

/**
 * Handles a open CCD app button on the manage fonts menu
 */
MarketplaceHeaderController.prototype._handleOpenCCDButton = function() {
  addPageAction('typekit.click.use-model-font-activation-open-app', { source: 'Manage Fonts' });
  this.FirstMileService.openCreativeCloudDesktop();
};

MarketplaceHeaderController.prototype.initEventHandlers = function(headerEl) {
  const self = this;

  const subNav = headerEl.querySelector('#header-subnav');
  if (subNav) {
    subNav.addEventListener(SEARCH_PARAM_CHANGED_EVENT_NAME, event => {
      const searchbar = event.target;
      self.FamilyAutocompleteSearchService.get(event.detail.searchParam).then(function(response) {
        searchbar.results = response.data;
      });
    });
  }

  const header = document.querySelector('header');
  headerEl.addEventListener(FlyoutEvent.EXPANDED_EVENT, () => {
    trapFocus();
    header.inert = false;
  });

  headerEl.addEventListener(FlyoutEvent.COLLAPSED_EVENT, () => releaseFocus());

  headerEl.addEventListener(CCDEvent.OPEN_CCD_EVENT, function() {
    self._handleOpenCCDButton();
  });

  headerEl.addEventListener(CCDEvent.CLOSE_CCD_TOUR_EVENT, () => {
    self.UserPrefsService.updatePrefs({ hideUseModelCCDTutorial: true });
  });

  self.UserPrefsService.getUserPrefs().then(function(userPrefs) {
    // Defaulting to `undefined` will remove the corresponding attribute from the element.
    self.showCoachmark = !userPrefs.hideUseModelCCDTutorial || undefined;
  });
};

/**
 * Preload data
 * @private
 */
MarketplaceHeaderController.prototype._preloadData = function() {
  const userInfo = getData('/user_info');
  if (!userInfo) {
    return;
  }

  setActivityState(userInfo.activity_state);
  setAdobeProfileId(userInfo.adobe_profile_id);
  setDaysSinceSyncActivity(userInfo.days_since_sync_activity);
  setPlan(userInfo.plan);
};

/**
 * Send custom attributes regarding user info and referrer to NewRelic.
 *
 * @private
 */
MarketplaceHeaderController.prototype._setNewrelicCustomAttributes = function() {
  var referrer = UrlService.getSearchParam('referrer');
  if (referrer) {
    setCustomAttribute('referrer', referrer);
  }

  setCustomAttribute('activityState', getActivityState());
  setCustomAttribute('adobeProfileId', getAdobeProfileId());
  setCustomAttribute('daysSinceSyncActivity', getDaysSinceSyncActivity());
  setCustomAttribute('plan', getPlan());
  setCustomAttribute('referrerHeader', getReferrerHeader());
};

MarketplaceHeaderController.prototype._handleSignInButton = function() {
  addPageAction('typekit.click.sign-in');
};

MarketplaceHeaderController.prototype._initSubHeader = function(navbarCombo) {
  const nav = navbarCombo.querySelector('#header-nav');
  const subnav = navbarCombo.querySelector('#header-subnav');

  if (!nav || !subnav || !subnav.hasAttribute('sticky')) {
    return;
  }

  const main = document.querySelector('main');
  new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      subnav.toggleAttribute('sticky', !entry.isIntersecting);
      if (entry.isIntersecting) {
        navbarCombo.appendChild(subnav);
      } else {
        main.prepend(subnav);
      }
    });
  }).observe(nav);
};

module.exports = MarketplaceHeaderController;
